import type { SignUpConfig } from '#types/config/components/form/signUp'

export default {
  showBirthDateField: true,
  minimalAge: 16,
  mobilePhone: {
    requiredLocales: [
      'en-us',
      'en-ca',
      'fr-ca'
    ]
  },
  onSuccessNavigateTo: '/account',
  agreementCheckboxes: [
    {
      type: 'loyaltyTerms',
      locales: ['en-us'],
      required: true
    },
    {
      type: 'policy',
      locales: ['en-us'],
      required: true
    },
    {
      type: 'newsletter'
    }
  ],
  isLoyaltyNewsletterAllowed: false
} satisfies SignUpConfig
