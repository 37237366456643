import type { QuickshopConfig } from '#types/config/components/dialog/quickshop'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    label: '',
    namePriceWrapper: 'flex items-center between pt-4',
    price: '!flex-nowrap !my-0',
    stickyFooter: 'flex gap-2',
    wrapper: ''
  },
  carousel: {
    brandClasses: {
      container: '-mx-5 px-5 gap-1',
      item: 'snap-center'
    },
    pagination: false
  },
  stickyFooter: true,
  title: 'editProduct',
  viewDetailsVariant: 'secondary'
} satisfies RecursivePartial<QuickshopConfig>
