import type { RecursivePartial } from '#types/utils'
import type { ProductListConfig } from '#types/config/components/product/list'

export default {
  grid: {
    gaps: {
      x: { sm: 'xs' },
      y: { sm: 'lg', md: 'xl' }
    }
  }
} satisfies RecursivePartial<ProductListConfig>
