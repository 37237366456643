import { AccountNavItemType } from '#root/enums/account'
import type { AccountConfig } from '#types/config/pages/account'
import type { RecursivePartial } from '#types/utils'

export default {
  // https://nuxt.com/docs/guide/directory-structure/app-config#merging-strategy - the merge strategy for configs must be a function to override an array
  navigation: (() => [
    AccountNavItemType.myAccount,
    AccountNavItemType.myOrders,
    AccountNavItemType.memberRewards,
    AccountNavItemType.signOut
  ]) as unknown as AccountConfig['navigation'],
  brandClasses: {
    heading: 'mt-6 title-5',
    navItem: 'hover:underlined',
    navItemActive: 'underlined'
  },
  brandStyles: {
    container: {
      'max-w': {
        sm: '61rem'
      }
    }
  },
  creditCards: {
    brandClasses: {
      formContainer: 'mx-a md:(mt-6 max-w-70) lg:max-w-100'
    }
  },
  address: {
    brandClasses: {
      formContainer: 'mx-a md:(mt-6 max-w-70) lg:max-w-100'
    }
  },
  orderHistory: {
    brandClasses: {
      notification: 'col-span-1 mb-0 lg:mb-6',
      itemsDetail: {
        container: 'mt-4 text-sm <md:hidden <lg:mt-2 space-y-2',
        hiddenItemsCount: 'b b-grey-80 bg-transparent text-base fw-medium'
      }
    },
    image: {
      height: {
        sm: 220,
        lg: 463
      },
      width: {
        sm: 176,
        lg: 370
      },
    },
    itemsCount: {
      sm: 3,
      md: 3,
    }
  },
  onErrorNavigateTo: '/'
} satisfies RecursivePartial<AccountConfig>
