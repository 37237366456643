import type { RecursivePartial } from '#types/utils'
import type { PdpConfig } from '#types/config/pages/pdp'

export default {
  brandClasses: {
    grid: 'md:(grid mb-8 items-start gap-x-5 gap-y-3 cols-12) <md:(mb-6 f-col)',
    heading: 'pt-5 lg:col-span-5 md:col-span-6 <md:mb-5',
    headingSticky: 'mb-3',
    gallery: '<md:order-first lg:col-span-7 md:(col-span-6 row-start-1 row-end-3 pt-5)',
    details: 'lg:col-span-5 md:(col-span-6 row-start-2 row-end-5)',
    detailsSticky: 'md:pt-5',
    shopTheLookHeading: 'text-center',
    shopTheLookWrapper: 'mt-10',
    shopTheLookCta: 'justify-center',
    eSpotsAndReviews: 'mt-4 md:mt-8 space-y-8',
    gridPartialWidth: 'lg:col-span-7 md:col-span-6',
    gridFullWidth: 'col-span-12'
  },
  disableGroupBy: true,
  gallery: {
    presets: {
      src: { width: 620, height: 776 },
      srcLarge: { width: 680, height: 850 },
      srcZoom: { width: 844, height: 1055 },
      srcThumbnail: { width: 156, height: 195 }
    }
  },
  hideBreadcrumbs: true,
  questions: {
    pageSize: 6
  },
  reviews: {
    nonFilterKeys: ['cons', 'pros'],
    pageSize: 6,
    mostLikedCommentTextThreshold: 225,
    recommendationRateThreshold: 74,
    showProsAndConsSnapshot: true,
    prosAndConsMaxNumber: 6,
    filterOptionsMaxNumber: 7,
    summaryHeight: '5.2rem'
  },
  seoMetaImage: '/img/logos/otw/default.svg',
  sizeChartWrapper: 'panel',
  stickyDetailsColumn: true,
  swatches: {
    collapseThreshold: 16,
    variant: 'rectangle'
  },
} satisfies RecursivePartial<PdpConfig>
