import type { PagesConfig } from '#types/config/pages'

export default {
  brandClasses: {
    formContainer: 'w-full pb-16 pt-4 container lg:max-w-100 md:(max-w-70 pt-5)',
    requiredField: 'mb-2 text-sm c-grey-20',
    submitButton: 'w-full'
  },
  hideCancelButton: true
} satisfies PagesConfig['resetPassword']
