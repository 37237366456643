import type { VfConfig } from '#types/config/components/vf'

export default {
  sizes: {
    default: {
      width: 72,
      height: 36
    }
  }
} satisfies VfConfig['logo']
