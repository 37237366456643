import type { VfConfig } from '#types/config/components/vf'

export default {
  variants: ['primary', 'secondary', 'tertiary'],
  sizes: ['xs', 'sm'],
  fallbackVariants: {
    'primary-inverse': 'primary',
    'secondary-inverse': 'secondary',
    'tertiary-inverse': 'tertiary'
  },
  fallbackSizes: {
    tiny: 'xs',
    md: 'sm'
  }
} satisfies VfConfig['button']
