import apiIndex from "/opt/build/repo/brands/otw/config/app/api/index"
import checkoutLoyalty from "/opt/build/repo/brands/otw/config/app/checkout/loyalty"
import componentsBaseGrid from "/opt/build/repo/brands/otw/config/app/components/base/grid"
import componentsCart from "/opt/build/repo/brands/otw/config/app/components/cart"
import componentsCheckoutApplePayButton from "/opt/build/repo/brands/otw/config/app/components/checkout/applePayButton"
import componentsCheckoutOrderItem from "/opt/build/repo/brands/otw/config/app/components/checkout/orderItem"
import componentsCheckoutPaymentLoyalty from "/opt/build/repo/brands/otw/config/app/components/checkout/paymentLoyalty"
import componentsCmsMegaMenuL1Mobile from "/opt/build/repo/brands/otw/config/app/components/cms/megaMenuL1Mobile"
import componentsCmsProductRecommendations from "/opt/build/repo/brands/otw/config/app/components/cms/productRecommendations"
import componentsCmsRichText from "/opt/build/repo/brands/otw/config/app/components/cms/richText"
import componentsDashboardCard from "/opt/build/repo/brands/otw/config/app/components/dashboard/card"
import componentsDashboardProfile from "/opt/build/repo/brands/otw/config/app/components/dashboard/profile"
import componentsDialogMiniCart from "/opt/build/repo/brands/otw/config/app/components/dialog/miniCart"
import componentsDialogQuickshop from "/opt/build/repo/brands/otw/config/app/components/dialog/quickshop"
import componentsDialogSignIn from "/opt/build/repo/brands/otw/config/app/components/dialog/signIn"
import componentsFormAddAddress from "/opt/build/repo/brands/otw/config/app/components/form/addAddress"
import componentsFormChangePassword from "/opt/build/repo/brands/otw/config/app/components/form/changePassword"
import componentsFormInterests from "/opt/build/repo/brands/otw/config/app/components/form/interests"
import componentsFormResetPassword from "/opt/build/repo/brands/otw/config/app/components/form/resetPassword"
import componentsFormSignUp from "/opt/build/repo/brands/otw/config/app/components/form/signUp"
import componentsKlarna from "/opt/build/repo/brands/otw/config/app/components/klarna"
import componentsOrderDetailsItem from "/opt/build/repo/brands/otw/config/app/components/order/detailsItem"
import componentsPaypalButton from "/opt/build/repo/brands/otw/config/app/components/paypal/button"
import componentsProductCard from "/opt/build/repo/brands/otw/config/app/components/product/card"
import componentsProductDefaultImageUrl from "/opt/build/repo/brands/otw/config/app/components/product/defaultImageUrl"
import componentsProductDetails from "/opt/build/repo/brands/otw/config/app/components/product/details"
import componentsProductList from "/opt/build/repo/brands/otw/config/app/components/product/list"
import componentsProductSwatches from "/opt/build/repo/brands/otw/config/app/components/product/swatches"
import componentsVfButton from "/opt/build/repo/brands/otw/config/app/components/vf/button"
import componentsVfColorPicker from "/opt/build/repo/brands/otw/config/app/components/vf/colorPicker"
import componentsVfFooter from "/opt/build/repo/brands/otw/config/app/components/vf/footer"
import componentsVfFormError from "/opt/build/repo/brands/otw/config/app/components/vf/form/error"
import componentsVfFormInput from "/opt/build/repo/brands/otw/config/app/components/vf/form/input"
import componentsVfFormLabel from "/opt/build/repo/brands/otw/config/app/components/vf/form/label"
import componentsVfFormSelect from "/opt/build/repo/brands/otw/config/app/components/vf/form/select"
import componentsVfIcon from "/opt/build/repo/brands/otw/config/app/components/vf/icon"
import componentsVfLogo from "/opt/build/repo/brands/otw/config/app/components/vf/logo"
import componentsVfMediaGallery from "/opt/build/repo/brands/otw/config/app/components/vf/mediaGallery"
import componentsVfNotification from "/opt/build/repo/brands/otw/config/app/components/vf/notification"
import componentsVfSearch from "/opt/build/repo/brands/otw/config/app/components/vf/search"
import componentsVfSizePicker from "/opt/build/repo/brands/otw/config/app/components/vf/sizePicker"
import componentsVfStepper from "/opt/build/repo/brands/otw/config/app/components/vf/stepper"
import componentsVfTable from "/opt/build/repo/brands/otw/config/app/components/vf/table"
import pagesAccount from "/opt/build/repo/brands/otw/config/app/pages/account"
import pagesCheckout from "/opt/build/repo/brands/otw/config/app/pages/checkout"
import pagesError from "/opt/build/repo/brands/otw/config/app/pages/error"
import pagesOrderStatus from "/opt/build/repo/brands/otw/config/app/pages/orderStatus"
import pagesPdp from "/opt/build/repo/brands/otw/config/app/pages/pdp"
import pagesPlp from "/opt/build/repo/brands/otw/config/app/pages/plp"
import pagesProfile from "/opt/build/repo/brands/otw/config/app/pages/profile"
import pagesResetPassword from "/opt/build/repo/brands/otw/config/app/pages/resetPassword"
import pagesSignIn from "/opt/build/repo/brands/otw/config/app/pages/signIn"
import pagesStoreLocator from "/opt/build/repo/brands/otw/config/app/pages/storeLocator"
import seoDataRobots from "/opt/build/repo/brands/otw/config/app/seoData/robots"
    
export default {"api":apiIndex,"checkout":{"loyalty":checkoutLoyalty},"components":{"base":{"grid":componentsBaseGrid},"cart":componentsCart,"checkout":{"applePayButton":componentsCheckoutApplePayButton,"orderItem":componentsCheckoutOrderItem,"paymentLoyalty":componentsCheckoutPaymentLoyalty},"cms":{"megaMenuL1Mobile":componentsCmsMegaMenuL1Mobile,"productRecommendations":componentsCmsProductRecommendations,"richText":componentsCmsRichText},"dashboard":{"card":componentsDashboardCard,"profile":componentsDashboardProfile},"dialog":{"miniCart":componentsDialogMiniCart,"quickshop":componentsDialogQuickshop,"signIn":componentsDialogSignIn},"form":{"addAddress":componentsFormAddAddress,"changePassword":componentsFormChangePassword,"interests":componentsFormInterests,"resetPassword":componentsFormResetPassword,"signUp":componentsFormSignUp},"klarna":componentsKlarna,"order":{"detailsItem":componentsOrderDetailsItem},"paypal":{"button":componentsPaypalButton},"product":{"card":componentsProductCard,"defaultImageUrl":componentsProductDefaultImageUrl,"details":componentsProductDetails,"list":componentsProductList,"swatches":componentsProductSwatches},"vf":{"button":componentsVfButton,"colorPicker":componentsVfColorPicker,"footer":componentsVfFooter,"form":{"error":componentsVfFormError,"input":componentsVfFormInput,"label":componentsVfFormLabel,"select":componentsVfFormSelect},"icon":componentsVfIcon,"logo":componentsVfLogo,"mediaGallery":componentsVfMediaGallery,"notification":componentsVfNotification,"search":componentsVfSearch,"sizePicker":componentsVfSizePicker,"stepper":componentsVfStepper,"table":componentsVfTable}},"pages":{"account":pagesAccount,"checkout":pagesCheckout,"error":pagesError,"orderStatus":pagesOrderStatus,"pdp":pagesPdp,"plp":pagesPlp,"profile":pagesProfile,"resetPassword":pagesResetPassword,"signIn":pagesSignIn,"storeLocator":pagesStoreLocator},"seoData":{"robots":seoDataRobots}}
