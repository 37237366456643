import type { PagesConfig } from '#types/config/pages'

export default {
  brandClasses: {
    submitButton: 'w-full',
    formContainer: 'mx-a md:(mt-6 max-w-70) lg:max-w-100'
  },
  newsletters: [
    { type: 'newsletter' }
  ],
  showPostalCode: {
    requiredLocales: ['en-us', 'en-ca', 'fr-ca']
  },
  interestOnlyForSubscription: {
    requiredLocales: ['en-us', 'en-ca', 'fr-ca']
  }
} satisfies PagesConfig['profile']
