import type { VfConfig } from '#types/config/components/vf'

export default {
  brandClasses: {
    table: 'b-b b-grey-70',
    header: 'bg-white',
    row: 'px-2 py-3',
    th: 'b-0 b-b b-grey-70 b-solid fw-medium',
    thXStuck: 'b-r b-grey-70 b-solid',
    thYStuck: 'b-b b-grey-50 b-solid',
    td: 'b-0 b-r last:b-r-0 b-grey-70 b-solid',
    tdStuck: 'b-r b-grey-50 b-solid'
  }
} satisfies VfConfig['table']
