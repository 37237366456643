import type { RecursivePartial } from '#types/utils'
import type { MiniCartConfig } from '#types/config/components/dialog/miniCart'

export default {
  brandClasses: {
    header: {
      wrapper: 'flex gap-x-1 b-b b-grey-80 pb-8 pt-3',
      heading: 'text-sm'
    },
    footer: 'bg-white flex gap-x-1 p-5 -m-5 shadow-lg space-y-0',
    product: 'b-b b-grey-80 pb-6 pt-5 space-y-2',
    totalItems: 'block m-a min-h-38 w-full p-5 text-left'
  },
  viewCartVariant: 'secondary'
} satisfies RecursivePartial<MiniCartConfig>
