import type { DetailsItemConfig } from '#types/config/components/order/detailsItem'
import type { RecursivePartial } from '#types/utils'

export default {
  brandClasses: {
    backToLink: 'flex items-center gap-1 <md:(mb-2 mt-8)',
    emphasizedText: 'fw-medium',
    billingSection: 'pt-6',
    containerHeading: 'mb-6 mt-8 items-center <md:(flex flex-col-reverse items-stretch)',
    informationSection: '',
    informationSectionDetails: '',
    itemDescriptionDetails: 'text-right',
    itemDescriptionDetailsTitle: 'pt-6',
    itemSummaryHeading: 'title-3 b-t b-grey-80 pt-6',
    orderInfoContainer: 'cols-3 lg:cols-4 md:grid',
    orderInfoSection: '<md:b-grey-80 divide-grey-80',
    shippingSection: 'pb-0 pr-0 lg:pr-4',
    trackingNumber: '<md:between pt-6'
  },
  trackReturnVariant: 'primary'
} satisfies RecursivePartial<DetailsItemConfig>
