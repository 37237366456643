import type { CMSConfig } from '#types/config/components/cms'

export default {
  addButtonSize: 'tiny',
  brandClasses: {
    container: '',
    carouselControls: '',
    carouselContainer: 'px-1.5',
    productContainer: 'px-0.5',
    addButton: 'self-start',
  },
  presets: {
    size: { width: { sm: 350, md: 230 }, height: { sm: 437, md: 288 } }
  }
} satisfies CMSConfig['productRecommendations']
