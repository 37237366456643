export default {
  size: {
    'xs': 10,
    'sm': 12,
    'md': 16,
    'lg': 24,
    'xl': 32,
    '2xl': 40,
    '3xl': 48,
    '4xl': 56,
    '5xl': 64
  }
}
