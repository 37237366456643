import type { RecursivePartial } from '#types/utils'
import type { ColorPickerConfig } from '#types/config/components/vf/colorPicker'

export default {
  thumbnail: {
    rectangle: {
      width: 150,
      height: 188
    }
  },
  rectangle: {
    width: {
      sm: '4.1875rem',
      md: '5.28125rem',
      lg: '5.34375rem'
    }
  }
} satisfies RecursivePartial<ColorPickerConfig>
